import { Injectable } from "@angular/core";
import { ExamAssignment } from "@app/exambundle/models/ExamAssignment.model";
import { Assignment } from "@app/shared/models/entities/course/Assignment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ExamAssignmentsStoreService {
  private readonly _assignments = new BehaviorSubject<ExamAssignment[]>([]);
  private readonly _index = new BehaviorSubject<number>(0);
  private readonly _currentAssignment = new BehaviorSubject<ExamAssignment | undefined>(undefined);
  private readonly _previousAssignment = new BehaviorSubject<ExamAssignment | undefined>(undefined);

  private readonly _assignmentGroups = new BehaviorSubject<ExamAssignment[][]>([]);
  private readonly _groupIndex = new BehaviorSubject<number | undefined>(undefined);
  private readonly _currentAssignmentGroup = new BehaviorSubject<ExamAssignment[]>([]);

  private readonly _nrAssignmentsCompleted = new BehaviorSubject<number | undefined>(undefined);
  private readonly _showCheatModal = new BehaviorSubject<boolean>(false);

  public readonly assignments$ = this._assignments.asObservable();
  public readonly index$ = this._index.asObservable();
  public readonly currentAssignment$ = this._currentAssignment.asObservable();
  public readonly previousAssignment$ = this._previousAssignment.asObservable();

  public readonly assignmentGroups$ = this._assignmentGroups.asObservable();
  public readonly groupIndex$ = this._groupIndex.asObservable();
  public readonly currentAssignmentGroup$ = this._currentAssignmentGroup.asObservable();

  public readonly nrAssignmentsCompleted$ = this._nrAssignmentsCompleted.asObservable();
  public readonly showCheatModal$ = this._showCheatModal.asObservable();

  public get assignments(): ExamAssignment[] {
    return this._assignments.getValue();
  }

  public set assignments(examAssignments: ExamAssignment[]) {
    examAssignments.forEach((examAssignment) => {
      examAssignment.assignment = new Assignment(examAssignment.assignment);
    });
    this._assignments.next(examAssignments);
    this.calculateAssignmentsCompleted();
  }

  public get index(): number {
    return this._index.getValue();
  }

  public set index(index: number) {
    if (index < 0) {
      this._index.next(0);
    } else if (index > this.assignments.length) {
      this._index.next(this.assignments.length);
    } else {
      this._index.next(index);
    }
    this.currentAssignment = this.assignments[this.index];
    this.showCheatModal = false;
  }

  public get currentAssignment(): ExamAssignment | undefined {
    return this._currentAssignment.getValue();
  }

  public set currentAssignment(currentAssignment: ExamAssignment | undefined) {
    this.previousAssignment = this.currentAssignment;
    this._currentAssignment.next(currentAssignment);
  }

  public get previousAssignment(): ExamAssignment | undefined {
    return this._previousAssignment.getValue();
  }

  public set previousAssignment(previousAssignment: ExamAssignment | undefined) {
    this._previousAssignment.next(previousAssignment);
  }

  public get assignmentGroups(): ExamAssignment[][] {
    return this._assignmentGroups.getValue();
  }

  public set assignmentGroups(assignmentGroups: ExamAssignment[][]) {
    this._assignmentGroups.next(assignmentGroups);
  }

  public get groupIndex(): number {
    return this._groupIndex.getValue() as number;
  }

  public set groupIndex(groupIndex: number) {
    if (groupIndex < 0) {
      this._groupIndex.next(0);
    } else if (groupIndex > this.assignmentGroups.length) {
      this._groupIndex.next(this.assignmentGroups.length);
    } else {
      this._groupIndex.next(groupIndex);
    }
    this.currentAssignmentGroup = this.assignmentGroups[this.groupIndex];
  }

  public get currentAssignmentGroup(): ExamAssignment[] {
    return this._currentAssignmentGroup.getValue();
  }

  public set currentAssignmentGroup(currentAssignmentGroup: ExamAssignment[]) {
    this._currentAssignmentGroup.next(currentAssignmentGroup);
  }

  public get nrAssignmentsCompleted(): number {
    return this._nrAssignmentsCompleted.getValue() as number;
  }

  public set nrAssignmentsCompleted(nrAssignmentsCompleted: number) {
    this._nrAssignmentsCompleted.next(nrAssignmentsCompleted);
  }

  public get showCheatModal(): boolean {
    return this._showCheatModal.getValue();
  }

  public set showCheatModal(showCheatModal: boolean) {
    this._showCheatModal.next(showCheatModal);
  }

  public calculateAssignmentsCompleted() {
    let assignmentsCompleted = 0;
    for (const assignment of this.assignments) {
      if (assignment.user_answers.length > 0) {
        assignmentsCompleted += 1;
      }
    }
    this.nrAssignmentsCompleted = assignmentsCompleted;
  }

  public getGroupIndex(assignment: ExamAssignment): number {
    let index = 0;
    this.assignmentGroups.forEach((group, groupIndex) => {
      group.forEach((groupAssignment) => {
        if (groupAssignment.id === assignment.id) {
          index = groupIndex;
        }
      });
    });
    return index;
  }

  public groupAssignments() {
    const assignmentGroups: ExamAssignment[][] = [];
    let currentIndex = 0;
    let currentSubIndex: number | undefined = undefined;
    this.assignments.forEach((assignment, index) => {
      if (currentSubIndex === undefined) {
        assignmentGroups.push([] as ExamAssignment[]);
        currentSubIndex = 0;
        assignmentGroups[currentIndex].push(assignment);
      } else if (assignment.assignment.sub_index > currentSubIndex) {
        assignmentGroups[currentIndex].push(assignment);
        currentSubIndex = assignment.assignment.sub_index;
      } else {
        assignmentGroups.push([]);
        currentIndex++;
        currentSubIndex = 0;
        assignmentGroups[currentIndex].push(assignment);
      }
      assignment.index = index;
      assignment.lastUserAnswer = this.getLastUserAnswer(assignment);
    });

    this.assignmentGroups = assignmentGroups;
    this.groupIndex = 0;
  }

  private getLastUserAnswer(assignment: ExamAssignment) {
    if (assignment.user_answers.length === 0) {
      return undefined;
    } else {
      return assignment.user_answers[assignment.user_answers.length - 1];
    }
  }
}
